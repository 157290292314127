<template>
  <div id="app">
    <Header
      v-if="
        !$route.meta.hiddenHeaderAndFooter && $route.path.indexOf('/manage') < 0
      "
    />
    <router-view />
    <el-backtop v-if="$route.path.indexOf('/manage') < 0" />
    <Footer
      v-if="
        !$route.meta.hiddenHeaderAndFooter && $route.path.indexOf('/manage') < 0
      "
    />
    <Theme v-if="$route.path.indexOf('/manage') < 0" />
  </div>
</template>

<script>
import Header from "@/components/content/Front/Header";
import Footer from "@/components/content/Front/Footer";
import Theme from "@/components/common/Theme";

import { mapState } from "vuex";
export default {
  components: { Header, Footer, Theme },
  computed: {
    ...mapState([
      "activeMouseTXID",
      "activeScreenTXID",
      "mouseScript",
      "screenScript",
    ]),
  },
  mounted() {},
  watch: {
    mouseScript: {
      immediate: true,
      handler(value) {
        if (!value) return;
        this.removeJs("24.js");
        this.removeJs("blast.js");
        this.removeJs("love.js");
        this.loadJs(value);
      },
    },
    screenScript: {
      immediate: true,
      handler(value) {
        if (!value) return;
        this.removeJs("sakura.js");
        this.loadJs(value);
      },
    },
    activeMouseTXID() {
      location.reload();
    },
    activeScreenTXID() {
      location.reload();
    },
  },
  methods: {
    loadJs(filename) {
      //如果文件类型为 .js ,则创建 script 标签，并设置相应属性

      var script = document.createElement("script");

      script.setAttribute("type", "text/javascript");

      script.setAttribute("src", filename);

      document.getElementsByTagName("head")[0].appendChild(script);
    },
    removeJs(filename) {
      //判断文件类型

      var targetelement = "script";

      //判断文件名

      var targetattr = "src";

      var allsuspects = document.getElementsByTagName(targetelement);

      //遍历元素， 并删除匹配的元素

      for (var i = allsuspects.length; i >= 0; i--) {
        if (
          allsuspects[i] &&
          allsuspects[i].getAttribute(targetattr) != null &&
          allsuspects[i].getAttribute(targetattr).indexOf(filename) != -1
        ) {
          allsuspects[i].parentNode.removeChild(allsuspects[i]);
        }
      }
    },

    //动态添加一个.js 文件
  },
};
</script>

<style lang="less">
.el-backtop {
  color: var(--backtop-font-color) !important;
  background-color: var(--backtop-bg) !important;
}
.el-card {
  border-color: transparent;
}
body,
html,
#app {
  height: 100%;
}
</style>
