var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-box",class:[
    _vm.shadow ? 'shadow' : '',
    _vm.animation,
    _vm.scroll ? 'overflow-scroll' : '',
  ],style:({
    'border-radius': _vm.bRNum + 'px',
    'background-color': _vm.backgroundColor + '!important',
    transition:
      _vm.addTransition('background-color') + ',' + _vm.addTransition('box-shadow'),
    padding: typeof _vm.padding === 'number' ? _vm.padding + 'px' : _vm.padding,
    display: _vm.block ? 'block' : 'inline-block',
    height: typeof _vm.height === 'number' ? _vm.height + 'px' : _vm.height,
    overflow: _vm.overflow ? 'hidden' : '',
    'margin-bottom': _vm.mb ? _vm.gapNum+'px':''
  })},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }