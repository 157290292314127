<template>
  <div
    class="theme-setting pointer hflex vcenter hcenter"
    :style="{ transform: `translateY(-${translateNum}px)` }"
  >
    <div class="sel-themes hflex vcenter hcenter">
      <div class="sel-themes-box">
        <i class="el-icon-moon" v-if="isLight" @click="changeTheme('dark')"></i>
        <i class="el-icon-sunny" v-else @click="changeTheme()"></i>
      </div>
    </div>
    <div class="setting hflex vcenter hcenter">
      <i class="el-icon-setting"></i>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      translateNum: 40,
      isLight: true,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleWindowScroll);
  },
  methods: {
    handleWindowScroll(e) {
      if (window.scrollY > 200) {
        this.translateNum = 50;
      } else {
        this.translateNum = 0;
      }
    },
    changeTheme(themeName) {
      if (themeName) {
        this.isLight = false;
        window.document.documentElement.classList.add(themeName);
      } else {
        this.isLight = true;
        window.document.documentElement.classList.remove("dark");
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleWindowScroll);
  },
};
</script>

<style lang="less" scoped>
@keyframes scaleAnimation {
  0% {
    transform: rotate(0) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(1.3);
  }
  100% {
    transform: rotate(0) scale(1);
  }
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.theme-setting {
  position: fixed;
  right: 40px;
  bottom: 40px;
  width: 40px;
  height: 40px;
  background-image: linear-gradient(to bottom right, red, blue);
  border-radius: 50px;
  transition: transform 1s, width 0.5s;
  z-index: 9;
  &:hover .setting i {
    animation: scaleAnimation 1.5s;
    -webkit-animation: scaleAnimation 1.5s;
  }
  .setting,
  .sel-themes {
    width: 40px;
    height: 40px;
    transition: all 0.5s;
  }
  .sel-themes-box {
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s;
  }
  &:hover .sel-themes-box {
    visibility: visible;
    opacity: 1;
  }
  .sel-themes {
    width: 0;
  }
  &:hover {
    width: 80px;
  }
  &:hover .sel-themes {
    width: 40px;
  }
  .el-icon-moon {
    color: var(--theme-color1);
  }
  .el-icon-sunny {
    animation: rotateAnimation 2s infinite linear;
    -webkit-animation: rotateAnimation 2s infinite linear;
    color: var(--theme-color2);
  }
  .setting i {
    background: linear-gradient(to right bottom, #ff9d00, #ff2200);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>
