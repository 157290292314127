import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "@/plugins/Vuetify";
import "@/assets/css/global.css";
import dayjs from "dayjs";

import "@wangeditor/editor/dist/css/style.css";
import "github-markdown-css/github-markdown.css";

import "./plugins";
import hljs from "highlight.js";
Vue.prototype.dayjs = dayjs;
Vue.config.productionTip = false;
console.log(process.env);

Vue.prototype.goto = function (path, query) {
  router.push({
    path,
    query,
  });
};

Vue.prototype.noneUserLogo = '/image/userNoneLogo.jpeg'


Vue.prototype.gotoUrl = (path) => {
  window.open(path, "__blank");
};

import { madeRandomCode, debounce } from "./utils/func";
Vue.prototype.madeRandomCode = madeRandomCode;
Vue.prototype.debounce = debounce;
let IMG_URL = process.env.VUE_APP_API;
Vue.prototype.IMG_URL = IMG_URL

Vue.prototype.showImg = (url) => {
  let regImg =
    /(https?:[^:<>"]*\/)([^:<>"]*)(\.((png!thumbnail)|(png)|(jpg)|(webp)))/;
  if (regImg.test(url)) {
    return url;
  } else {
    if (!url || url=='undefined') {
      return "/image/none.png";
    } else {
      return IMG_URL + url;
    }
  }
};

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  created() {
    document.addEventListener("DOMContentLoaded", (event) => {
      document.querySelectorAll("pre code").forEach((el) => {
        hljs.highlightElement(el);
      });
    });
  },
}).$mount("#app");
