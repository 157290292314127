import Vue from "vue";
import VueRouter from "vue-router";
import backRoutes from './backRoutes'
Vue.use(VueRouter);
import store from "@/store";
import { Message } from "element-ui";
const routes = [
  {
    path: "/",
    component: () => import("@/views/Front/Home"),
    meta: {
      title: "BC论坛网 - BC FORNUMS",
    },
  },
  {
    path: "/login",
    component: () => import("@/views/Front/Default/Login"),
    meta: {
      title: "登录",
      hiddenHeaderAndFooter: true,
    },
  },
  {
    path: "/register",
    component: () => import("@/views/Front/Default/Register"),
    meta: {
      title: "注册",
      hiddenHeaderAndFooter: true,
    },
  },
  {
    path: "/recover",
    component: () => import("@/views/Front/Default/Recover"),
    meta: {
      title: "找回密码",
      hiddenHeaderAndFooter: true,
    },
  },
  {
    path: "/about",
    component: () => import("@/views/Front/About"),
    meta: {
      title: "关于",
    },
  },
  {
    path: "/study",
    component: () => import("@/views/Front/Study"),
    meta: {
      title: "学习网站",
    },
  },
  {
    path: "/articles",
    component: () => import("@/views/Front/Articles"),
    meta: {
      title: "文章中心",
    },
  },
  {
    path: "/article",
    component: () => import("@/views/Front/ArticleDetail"),
    meta: {
      title: "文章详情",
    },
  },
];
routes.push(...backRoutes)

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from) => {
  document.title = to.meta.title;
});


let whiteArr = ['/manage/user','/manage/article','/manage/comment','/manage/type','/manage/link']

router.beforeEach((to,from,next) => {
  let user = store.state.userInfo
  if(to.path.includes('/manage')) {
    if(!user) {
      Message({
        type: "warning",
        message: '请先登录后再进入后台界面',
      });
      next('/login')
    }else {
      if(user.user.type === 1 && whiteArr.includes(to.path)) {
        Message({
          type: "warning",
          message: '非管理员不可进入管理员界面',
        });
        next('/manage')
      }else {
        next()
      }
    }
  }else {
    next()
  }
})

export default router;
