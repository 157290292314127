<template>
  <div class="footer">
    <div class="container-content">
      <header>
        <ul>
          <li v-for="item in nav" :key="item.name">
            <router-link :to="item.path">{{ item.name }}</router-link>
          </li>
        </ul>
        <div class="logo">
          <img src="@/assets/image/BClogo.png" alt="" />
        </div>
      </header>
    </div>
    <div class="line"></div>
    <div class="container-content">
      <div class="desc"><a href="https://beian.miit.gov.cn/" target="_blank">湘ICP备2024079214号-1</a></div>
    </div>
  </div>
</template>

<script>
import { frontRoutes } from "@/store/routes";
export default {
  data() {
    return {
      nav: [],
    };
  },

  mounted() {
    this.nav = frontRoutes;
  },

  methods: {},
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 768px) {
  .container-content .logo {
    display: none;
  }
  .container-content header {
    justify-content: center!important;
    ul {
      padding: 20px 0;
    }
  }
}
.footer {
  background-color: #fff;
  header {
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ul {
      display: flex;
      li {
        margin-right: 10px;
        font-size: 14px;
        text-align: center;
      }
    }
    img {
      height: 50px;
    }
  }
  .line {
    height: 1px;
    background-color: #f1f1f1;
  }
  a {
    color: #7b7b7b;
  }
  .desc {
    text-align: center;
    padding: 15px 0;
    font-size: 14px;
    color: #7b7b7b;
  }
}
</style>
