export const frontRoutes = [
  {
    path: "/",
    name: "首页",
  },
  {
    path: "/articles",
    name: "文章中心",
  },
  {
    path: "/study",
    name: "学习网站",
  },
  {
    path: "/about",
    name: "关于",
  },
];

let AllRoutes = [
  {
    path: "/manage",
    name: "主界面",
    icon: "iconfont icon-shouyefill",
    isManager: false,
  },
  {
    path: "/manage/person",
    name: "个人设置",
    icon: "iconfont icon-yonghu",
    isManager: false,
  },
];

let userRoutes = [
  {
    path: '/manage/userArticle',
    name: '我的文章',
    icon: 'iconfont icon-16',
    isManager:false
  },
  {
    path: '/manage/collect',
    name: '我的收藏',
    icon: 'iconfont icon-shoucang1',
    isManager:false
  }
]

let manageRoutes = [
  {
    path: "/manage/user",
    name: "用户管理",
    icon: "iconfont icon-yonghuguanli",
    isManager: true,
  },
  {
    path: "/manage/article",
    name: "文章管理",
    icon: "iconfont icon-guanliwenzhang",
    isManager: true,
  },
  {
    path: "/manage/comment",
    name: "评论管理",
    icon: "iconfont icon-pinglun",
    isManager: true,
  },
  {
    path: "/manage/type",
    name: "分类管理",
    icon: "el-icon-menu",
    isManager: true,
  },
  {
    path: "/manage/link",
    name: "链接管理",
    icon: "el-icon-link",
    isManager: true,
  },
];

let setting = {
  path: "/manage/setting",
  name: "系统设置",
  icon: "el-icon-s-tools",
  isManager: false,
};

AllRoutes.push(...userRoutes,...manageRoutes,setting);

export const backRoutes = AllRoutes;
