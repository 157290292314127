<template>
  <div
    class="card-box"
    :class="[
      shadow ? 'shadow' : '',
      animation,
      scroll ? 'overflow-scroll' : '',
    ]"
    :style="{
      'border-radius': bRNum + 'px',
      'background-color': backgroundColor + '!important',
      transition:
        addTransition('background-color') + ',' + addTransition('box-shadow'),
      padding: typeof padding === 'number' ? padding + 'px' : padding,
      display: block ? 'block' : 'inline-block',
      height: typeof height === 'number' ? height + 'px' : height,
      overflow: overflow ? 'hidden' : '',
      'margin-bottom': mb ? gapNum+'px':''
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  props: {
    padding: {
      type: String | Number,
      default: 15,
    },
    block: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String | Number,
      default: "100%",
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: "",
    },
    animation: {
      type: String,
      default: "",
    },
    overflow: {
      type: Boolean,
      default: false,
    },
    scroll: {
      type: Boolean,
      default: false,
    },
    mb: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    ...mapState(['gapNum',"bRNum", "transitionMode", "transitionTime"]),
    ...mapGetters(["addTransition"]),
  },
};
</script>

<style lang="less" scoped>
.card-box {
  background-color: var(--back-cardBg-color);
  &.shadow {
    box-shadow: 0 0 3px 1px var(--back-cardShadow-color);
    &:hover {
      cursor: pointer;
      box-shadow: 0 0 5px 1px var(--back-cardShadow-hover-color);
    }
  }
}
</style>
