import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

let state = JSON.parse(localStorage.getItem("state")) || {
  bRNum: 10, // 0 - 20 || 单位 px
  gapNum: 15, // 10 - 30 || 单位 px
  htmlPadding: 1, // 0 - 5 || 单位 %
  appPadding: 15, // 0 - 20 || 单位 px
  logoutPosition: 0, // nav || header
  transitionTime: 0.5,
  transitionMode: "linear",
  fontThemeColor: 0, // 文字主题色
  /**
   * 特效存储
   *  mouse | screen
   * */
  // 鼠标特效
  activeMouseTXID: 0,
  historyMouseTXID: 0,
  activeMouseTX: true,
  mouseScript: "",
  // 界面特效
  activeScreenTXID: 0,
  historyScreenTXID: 0,
  activeScreenTX: true,
  screenScript: "",
  // 用户信息
  userInfo: ''
};

export default new Vuex.Store({
  state,
  getters: {
    addTransition: (state) => (property, time, mode) =>
      `${property} ${time ? time : state.transitionTime}s ${
        mode ? mode : state.transitionMode
      }`,
  },
  mutations: {
    changeSettingProperty(state, { key, value }) {
      state[key] = value;
      if (key === "activeMouseTXID") {
        if (value === 0) {
          state.mouseScript = ''
        }else if(value === 1) {
          state.mouseScript = '/js/love.js'
        }else if(value === 2) {
          state.mouseScript = '/js/blast.js'
        }else if(value === 3) {
          state.mouseScript = '/js/24.js'
        }
      }
      if (key === "activeScreenTXID") {
        if (value === 0) {
          state.screenScript = ''
        }else if(value === 1) {
          state.screenScript = '/js/sakura.js'
        }
      }
      localStorage.setItem("state", JSON.stringify(state));
    },
    setUserInfo(state,payload) {
      state.userInfo = payload
      localStorage.setItem("state", JSON.stringify(state));
    },
    logout(state) {
      state.userInfo = ''
      localStorage.removeItem('token')
      localStorage.setItem("state", JSON.stringify(state));
    }
  },
  actions: {},
  modules: {},
});
