import Vue from "vue";

import Layout from "@/components/content/Layout";
import BackTitle from "@/components/content/Back/Title";
import BackCard from "@/components/content/Back/Card";
import BackGrid from "@/components/content/Back/Grid";
import Pager from "@/components/common/Pager";
Vue.component("Layout", Layout);
Vue.component("BackTitle", BackTitle);
Vue.component("BackCard", BackCard);
Vue.component("BackGrid", BackGrid);
Vue.component("Pager", Pager);