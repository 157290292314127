<template>
  <div
    class="back-title hflex vcenter"
    :style="{
      transition: addTransition('color'),
      'font-size': fontSize + 'px',
      'text-align': textAlign
    }"
  >
    <div><i v-if="icon" :class="icon" :style="{'margin-right': iconMargin +'px'}"></i>{{text}}</div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  props: {
    fontSize: {
      type:Number,
      default: 16
    },
    text: {
      type:String,
      default: ''
    },
    textAlign: {
      type:String,
      default:'left'
    },
    icon: {
      type:String,
      default: ''
    },
    iconMargin:{
      type:Number,
      default: 5
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapState([ "transitionMode", "transitionTime"]),
    ...mapGetters(["addTransition"]),
  },
};
</script>

<style lang="less" scoped>
.back-title {
    color: var(--back-title-color);
    font-weight: 600;
}
</style>
