<template>
  <div class="header">
    <div class="header-container">
      <div class="container">
        <div class="icon-logo">
          <img src="@/assets/image/BClogo.png" alt="" />
        </div>
        <nav class="pc-nav">
          <ul>
            <li
              :class="{ 'active-nav': $route.path === item.path }"
              :key="index"
              v-for="(item, index) in nav"
              @click="(activeIndex = index), $router.push(item.path)"
            >
              {{ item.name }}
            </li>
            <li>
              <div v-if="!userInfo">
                <router-link to="/login">登录</router-link> /
                <router-link to="/register">注册</router-link>
              </div>
              <el-dropdown v-else class="flex-center" style="height: 100%">
                <div class="hflex vcenter">
                  <img
                    :src="
                      userInfo?.user?.logo ? IMG_URL + userInfo.user.logo : noneUserLogo
                    "
                    style="
                      width: 45px;
                      height: 45px;
                      border-radius: 50%;
                      object-fit: cover;
                    "
                  />
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="goto('/manage')"
                    >个人中心</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="goto('/manage/userArticle')"
                    >我的文章</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="goto('/manage/person')"
                    >个人设置</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="goto('/manage/collect')"
                    >我的收藏</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="goto('/manage/setting')"
                    >系统设置</el-dropdown-item
                  >
                  <el-dropdown-item disabled></el-dropdown-item>
                  <el-dropdown-item divided @click.native="logoutBtn"
                    >退出登录</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </li>
          </ul>
        </nav>
        <i class="iconfont icon-menu" @click="isShowMbNav = true"></i>
        <div
          :style="{ transform: isShowMbNav ? 'translateX(0)' : '' }"
          class="mb-nav vflex hcenter vcenter"
        >
          <div class="el-icon-close" @click="isShowMbNav = false"></div>
          <header class="hflex hcenter pt-20 pb-20">
            <div v-if="!userInfo">
              <router-link to="/login">登录</router-link>
              <span class="ml-5 mr-5">/</span>
              <router-link to="/register">注册</router-link>
            </div>
            <div v-else class="hflex vcenter">
              <img
                :src="userInfo?.user?.logo ? IMG_URL + userInfo.user.logo : noneUserLogo"
                alt=""
                class="logo"
              />
              <div class="name">欢迎您,{{ userInfo.user.username }}</div>
            </div>
          </header>
          <nav class="vflex hcenter pl-20 pr-20">
            <div
              v-for="(item, index) in nav"
              :key="index"
              @click="goto(item.path), (isShowMbNav = false)"
              :class="$route.path === item.path ? 'active' : ''"
            >
              {{ item.name }}
            </div>
            <div @click="goto('/manage')">
              <i class="el-icon-setting"></i> 进入后台
            </div>
          </nav>
        </div>
        <div
          class="mb-coverbox"
          v-show="isShowMbNav"
          @click="isShowMbNav = false"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { frontRoutes } from "@/store/routes";
import { mapState, mapMutations } from "vuex";
export default {
  name: "VueIndex",
  data() {
    return {
      nav: [],
      activeIndex: 0,
      isShowMbNav: false,
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },

  mounted() {
    this.nav = frontRoutes;
    console.log(this.userInfo)
  },

  methods: {
    ...mapMutations(["logout"]),
    logoutBtn() {
      this.logout();
      this.$message({
        type: "success",
        message: "退出登录成功!",
      });
      this.goto("/");
    },
  },
};
</script>

<style lang="less" scoped>
.active-nav {
  position: relative;
  background-color: var(--bg-hover-color);
  color: var(--bg-active-color);
  &::before {
    content: "";
    transform: translateX(0) !important;
  }
}
.mb-coverbox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.4);
}
.icon-menu,
.mb-nav {
  display: none!important;
}
@media screen and (max-width: 768px) {
  .header-container {
    .container {
      align-items: center;
      padding: 0 20px;
    }
    .pc-nav {
      display: none;
    }
    .icon-menu {
      font-size: 20px;
      cursor: pointer;
      display: block!important;
    }
    .icon-logo {
      height: 40px;
      img {
        height: 100% !important;
      }
    }
    .mb-nav {
      display: flex!important;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      width: 70%;
      z-index: 100;
      background-color: #fff;
      transform: translateX(100%);
      transition: all 0.5s;
      padding: 30px 0;
      .el-icon-close {
        position: absolute;
        top: 10px;
        right: 10px;
        color: #000;
        font-size: 20px;
      }
      & > header,
      & > nav {
        width: 100%;
      }
      .logo {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
      }
      nav div {
        padding: 10px;
        margin-bottom: 10px;
        width: 100%;
        text-align: center;
        border: 1px solid #eee;
        border-radius: 8px;
        &:last-child {
          margin-bottom: 0;
          background-color: #393939;
          color: #d3d3d3;
        }
        &.active {
          background-color: var(--font-color);
          color: #fff;
        }
      }
    }
  }
}

@headerHeight: 60px;
.header {
  min-height: @headerHeight;
  max-height: @headerHeight;
  margin-bottom: var(--box-gutter);
}
.header-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9;
  min-height: @headerHeight;
  max-height: @headerHeight;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 5%);
  color: var(--font-color);
  .container {
    display: flex;
    justify-content: space-between;
    height: @headerHeight;
    .icon-logo {
      display: flex;
      align-items: center;
      img {
        height: calc(@headerHeight - 5px);
      }
    }
    nav {
      ul {
        display: flex;
        height: 100%;
        align-items: center;
        font-size: 14px;
        li {
          flex: 1;
          height: 100%;
          width: 80px;
          line-height: @headerHeight;
          text-align: center;
          cursor: pointer;
          position: relative;
          overflow: hidden;
          &:nth-child(-n + 4)::before {
            content: "";
            height: 2px;
            border-radius: 10px;
            background-color: var(--bg-active-color);
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            transition: all 0.3s;
            transform: translateX(-100%);
          }
          &:nth-child(-n + 4):hover {
            color: var(--bg-active-color);
            background-color: var(--bg-hover-color);
          }
          &:nth-child(-n + 4):hover::before {
            transform: translateX(0) !important;
          }
          &:last-child {
            cursor: auto;
            width: 120px;
          }
        }
        a {
          color: var(--font-color);
        }
      }
    }
  }
}
</style>
