<template>
  <div class="grid-box" :style="cssVars">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    rows: {
      type: Number,
      default: 2,
    },
    columns: {
      type: Number,
      default: 2,
    },
    gap: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {};
  },
  computed: {
    cssVars() {
      return {
        "--gapNum": this.gap + "px",
        "--rows-count": this.getColumns(this.rows),
        "--cols-count": this.getColumns(this.columns),
      };
    },
    getColumns() {
      return (count) => {
        switch (count) {
          case 1:
            return "1fr";
          case 2:
            return "1fr 1fr";
          case 3:
            return "1fr 1fr 1fr";
          case 4:
            return "1fr 1fr 1fr 1fr";
          default:
            return "1fr";
        }
      };
    },
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.grid-box {
  width: 100%;
  height: 100%;
  & > div {
    height: 100%;
    display: grid;
    grid-template-rows: var(--rows-count);
    grid-template-columns: var(--cols-count);
    gap: var(--gapNum);
  }
}
</style>
