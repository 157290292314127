<template>
  <div class="container-content">
    <el-row :gutter="gutter">
      <el-col :md="6" :sm="5" :xs="0">
        <slot name="left"></slot>
      </el-col>
      <el-col :md="12" :sm="14">
        <slot></slot>
      </el-col>
      <el-col :md="6" :sm="5" :xs="0">
        <slot name="right"></slot>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    gutter: {
      type: Number,
      default: 10,
    },
  },
};
</script>

<style lang="less" scoped></style>
