
// 生产随机验证码
export function madeRandomCode() {
  var arr = [];
  for (let i = 0; i < 4; i++) {
    var num = parseInt(Math.random() * 123);
    if (num >= 0 && num <= 9) {
      arr.push(num);
    } else if ((num >= 97 && num <= 122) || (num >= 65 && num <= 90)) {
      arr.push(String.fromCharCode(num));
    } else {
      i--;
    }
  }
  return arr.join("");
}

export function debounce(func, wait, immediate) {
  var timeout, result;

  var debounced = function () {
    var context = this;
    var args = arguments;
    if (timeout) clearTimeout(timeout);

    var later = function () {
      timeout = null;
      if (!immediate) result = func.apply(context, args);
    };

    var callNow = immediate && !timeout;
    timeout = setTimeout(later, wait);
    if (callNow) result = func.apply(this, args);

    return result;
  };
  debounced.cancel = function () {
    clearTimeout(timeout);
    timeout = null;
  };
  return debounced;
}
