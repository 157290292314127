let routes = [
    {
        path: '/manage',
        component: () => import('@/views/Back'),
        name: 'manage',
        meta: {
            title: '后台中心'
        },
        children: [
            {
                path: '/manage',
                component: () => import('@/views/Back/Home'),
                name: 'manageHomePage',
                meta: {
                    title: '主界面'
                }
            },
            {
                path: '/manage/person',
                component: () => import('@/views/Back/UserSetting'),
                name: 'userSetting',
                meta: {
                    title: '个人设置'
                }
            },
            {
                path: '/manage/setting',
                component: () => import('@/views/Back/SystemSetting'),
                name: 'systemSetting',
                meta: {
                    title: '系统设置'
                }
            },
            {
                path: '/manage/user',
                component: () => import('@/views/Back/UserManage'),
                name: 'userManage',
                meta: {
                    title: '用户管理'
                }
            },
            {
                path: '/manage/article',
                component: () => import('@/views/Back/ArticleManage'),
                name: 'articleManage',
                meta: {
                    title: '文章管理'
                }
            },
            {
                path: '/manage/comment',
                component: () => import('@/views/Back/CommentManage'),
                name: 'commentManage',
                meta: {
                    title: '评论管理'
                }
            },
            {
                path: '/manage/type',
                component: () => import('@/views/Back/TypeManage'),
                name: 'typeManage',
                meta: {
                    title: '分类管理'
                }
            },
            {
                path: '/manage/link',
                component: () => import('@/views/Back/LinkManage'),
                name: 'linkManage',
                meta: {
                    title: '链接管理'
                }
            },
            {
                path: '/manage/userArticle',
                component: () => import('@/views/Back/Article'),
                name: 'userArticle',
                meta: {
                    title: '我的文章'
                }
            },
            {
                path: '/manage/collect',
                component: () => import('@/views/Back/CollectManage'),
                name: 'collectManage',
                meta: {
                    title: '我的收藏'
                }
            },
            
        ]
    }
]

export default routes