<template>
  <div class="hflex hcenter mt-20">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      @prev-click="prevClick"
      @next-click="nextClick"
      :background="background"
      :small="small"
      :layout="
        `${showTotal?'total,':''}${sizer?'sizes,':''}prev, pager, next${jumper?',jumper':''}`
      "
      :total="total"
      :page-size="pageSize"
      :page-sizes="pageSizes"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    background: {
      type: Boolean,
      default: true,
    },
    sizer: {
      type: Boolean,
      default: false,
    },
    jumper: {
      type: Boolean,
      default: false,
    },
    showTotal: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 1000,
    },
    pageSize: {
      type: Number,
      default: 5,
    },
    pageSizes: {
      type: Array,
      default() {
        return [5, 10, 15, 20];
      },
    },
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {
    handleSizeChange(val) {
      this.$emit("handleSizeChange", val);
    },
    handleCurrentChange(val) {
      this.$emit("handleCurrentChange", val);
    },
    prevClick(val) {
      this.$emit("prevClick", val);
    },
    nextClick(val) {
      this.$emit("nextClick", val);
    },
  },
};
</script>

<style lang="scss" scoped></style>
